// DesktopIcon.js
import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  cursor: pointer;
  width: 80px;  // Set a fixed width for consistency
`;

const IconImage = styled.img`
  width: 48px;
  height: 48px;
  object-fit: contain;  // Ensure the image fits within the dimensions
`;

const IconText = styled.span`
  color: white;
  font-size: 12px;
  text-align: center;
  text-shadow: 1px 1px 1px black;
  margin-top: 5px;
  word-wrap: break-word;  // Allow text to wrap
  max-width: 100%;  // Ensure text doesn't overflow the icon width
`;

const DesktopIcon = ({ icon, name, onClick }) => (
  <IconWrapper onClick={onClick}>
    <IconImage src={icon} alt={name} />
    <IconText>{name}</IconText>
  </IconWrapper>
);

export default DesktopIcon;