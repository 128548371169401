// LoginPage.jsx
import React, { useState } from 'react';
import styled from 'styled-components';

const LoginWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Tahoma', sans-serif;
  color: white;
  position: relative;
`;

const TopBand = styled.div`
  background-color: #0A246A;
  height: 60px;

  @media (min-width: 768px) {
    height: 80px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: linear-gradient(to bottom, #235bce 0%, #6089d5 100%);

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const LeftSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;

  @media (min-width: 768px) {
    padding-left: 10%;
    border-right: 2px solid white;
    align-items: center;
    text-align: left;
  }
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media (min-width: 768px) {
    align-items: flex-start;
    margin-left: 50px;
  }
`;

const WelcomeText = styled.div`
  font-size: 20px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const Logo = styled.img`
  width: 150px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    width: 200px;
  }
`;

const InstructionText = styled.div`
  font-size: 14px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

const UserList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 300px;

  @media (min-width: 768px) {
    gap: 30px;
  }
`;

const UserButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  padding: 5px;
  border-radius: 3px;
  width: 100%;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const UserIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;

  @media (min-width: 768px) {
    width: 48px;
    height: 48px;
  }
`;

const UserName = styled.span`
  font-size: 14px;
  color: white;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

const BottomBand = styled.div`
  background-color: #0A246A;
  height: 60px;
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    height: 80px;
  }
`;

const Footer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    bottom: 20px;
    left: 20px;
  }
`;

const TurnOffButton = styled.button`
  background-color: transparent;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const PowerIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;

  @media (min-width: 768px) {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
`;

const LoginPage = ({ onLogin }) => {
    const [selectedUser, setSelectedUser] = useState(null);

    const users = [
        { id: 1, name: 'Dad', icon: '/guitar.png' },
        { id: 2, name: 'Kim', icon: '/duck.png' },
        { id: 3, name: 'Mom', icon: '/ball.png' },
        { id: 4, name: "User", icon: '/skater.png' },
    ];

    const handleUserClick = (user) => {
        setSelectedUser(user);
        // In a real application, you'd handle authentication here
        setTimeout(() => onLogin(user), 1000);
    };

    const handleTurnOff = () => {
        // Attempt to close the tab
        window.close();

        // If the tab doesn't close (which is likely), show a message to the user
        setTimeout(() => {
            if (!window.closed) {
              alert("If the tab didn't close automatically, please close it manually to exit Windows XP.");
            }
          }, 300);
    };

    return (
        <LoginWrapper>
            <TopBand />
            <ContentWrapper>
                <LeftSection>
                    <WelcomeText>Welcome to Windows XP</WelcomeText>
                    <Logo src="/xp-logo.png" alt="Windows XP" />
                    <InstructionText>To begin, click your user name</InstructionText>
                </LeftSection>
                <RightSection>
                    <UserList>
                        {users.map((user) => (
                            <UserButton key={user.id} onClick={() => handleUserClick(user)}>
                                <UserIcon src={user.icon} alt={user.name} />
                                <UserName>{user.name}</UserName>
                            </UserButton>
                        ))}
                    </UserList>
                </RightSection>
            </ContentWrapper>
            <BottomBand>
                <Footer>
                    <TurnOffButton onClick={handleTurnOff}>
                        <PowerIcon src="/shutdown.png" alt="Power" />
                        Turn off computer
                    </TurnOffButton>
                </Footer>
            </BottomBand>
        </LoginWrapper>
    );
};

export default LoginPage;