// Desktop.jsx
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Window from './Window';
import DesktopIcon from './DesktopIcon';
import Taskbar from './Taskbar';

const DesktopWrapper = styled.div`
  background-image: url('/background.webp');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  cursor: default;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Desktop = () => {
  const [windows, setWindows] = useState([]);
  const [clickSound] = useState(new Audio('/click.wav'));
  const [explosionSound] = useState(new Audio('/explosion.wav'));

  const icons = [
    { name: 'My Computer', icon: '/mycomputer.png' },
    { name: 'Internet Explorer', icon: '/internetexplorer.png' },
    { name: 'Recycle Bin', icon: '/recycle-bin.png' },
    { name: 'EtherVista', icon: '/ethervista.webp' },
    { name: 'McAfee', icon: '/mcafee.png' },
    { name: 'Telegram', icon: '/telegram.png' },
    { name: 'X', icon: '/twitter.svg' },

  ];

  useEffect(() => {
    const handleClick = () => {
      clickSound.play();
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [clickSound]);

  const handleIconClick = (iconName) => {
    switch (iconName) {
      case 'My Computer':
        openWindow('myComputer', 'My Computer');
        break;
      case 'Internet Explorer':
        window.open('https://www.google.com', '_blank');
        break;
      case 'Recycle Bin':
        openWindow('recycleBin', 'Recycle Bin');
        break;
      case 'EtherVista':
        window.open('https://ethervista.app/', '_blank');
        break;
      case 'McAfee':
        createPopups();
        break;
        case 'Telegram':
          window.open('https://t.me/mcafeevista', '_blank');
          break;
          case 'X':
            window.open('https://x.com/McafeeVista', '_blank');
            break;
      default:
        break;
    }
  };

  const popupMessages = [
    "Buy $MCAFEE on ETHERVISTA now!",
    "Limited time offer: 50% off $MCAFEE!",
    "Secure your future with $MCAFEE tokens!",
    "Don't miss out on $MCAFEE - The next big thing!",
    "Invest in $MCAFEE before it's too late!",
    "Your PC may be at risk! Scan now with McAfee!",
    "Upgrade to McAfee Premium for ultimate protection!",
    "Crypto-mining detected! Secure your PC with McAfee!",
    "New threats discovered! Update McAfee now!",
    "McAfee: The #1 choice for crypto enthusiasts!",
    "Protect your wallet with McAfee CryptoShield!",
    "McAfee: Now with built-in VPN for anonymous trading!",
    "Attention: Your system needs urgent care! Run McAfee now!",
    "McAfee detects 1337 threats on your PC! Act now!",
    "Special offer: McAfee + $MCAFEE bundle! Save big!"
  ];

  const createPopups = () => {
    const shuffledMessages = [...popupMessages].sort(() => 0.5 - Math.random());
    const newWindows = shuffledMessages.map((message, index) => ({
      id: `mcafee-${Date.now()}-${index}`,
      title: 'McAfee Alert',
      content: message,
      position: { 
        x: Math.random() * (window.innerWidth - 300), 
        y: Math.random() * (window.innerHeight - 200) 
      },
      isMinimized: false,
      isMcAfee: true
    }));

    setWindows(prevWindows => [
      ...prevWindows.filter(w => !w.isMcAfee),
      ...newWindows
    ]);

    explosionSound.play();
  };

  const openWindow = (id, title, content, isMcAfee = false) => {
    const newWindow = {
      id,
      title,
      content: content || getWindowContent(id),
      position: { x: Math.random() * (window.innerWidth - 300), y: Math.random() * (window.innerHeight - 200) },
      isMinimized: false,
      isMcAfee
    };

    setWindows(prevWindows => {
      const existingWindow = prevWindows.find(w => w.id === id);
      if (existingWindow) {
        return prevWindows.map(w => w.id === id ? { ...w, isMinimized: false } : w);
      } else {
        return [...prevWindows, newWindow];
      }
    });
  };

  const closeWindow = (id) => {
    setWindows(prevWindows => prevWindows.filter(w => w.id !== id));
  };

  const minimizeWindow = (id) => {
    setWindows(prevWindows => prevWindows.map(w => w.id === id ? { ...w, isMinimized: true } : w));
  };

  const updateWindowPosition = (id, newPosition) => {
    setWindows(prevWindows => 
      prevWindows.map(w => w.id === id ? { ...w, position: newPosition } : w)
    );
  };

  const getWindowContent = (id) => {
    switch (id) {
      case 'myComputer':
        return (
          <div>
            <h2>WAGMI-3000</h2>
            <p>Processor: EtherVista 64-bit</p>
            <p>RAM: 420 GB</p>
            <p>Storage: 69 TB SSD</p>
            <p>Operating System: Windows XP Web3 Edition</p>
          </div>
        );
      case 'recycleBin':
        return <p>The Recycle Bin is empty.</p>;
      default:
        return null;
    }
  };

  const handleTaskbarWindowClick = (id) => {
    setWindows(prevWindows => {
      if (id === 'mcafee') {
        const allMinimized = prevWindows.every(w => w.isMcAfee ? w.isMinimized : true);
        return prevWindows.map(w => w.isMcAfee ? { ...w, isMinimized: !allMinimized } : w);
      } else {
        return prevWindows.map(w => w.id === id ? { ...w, isMinimized: !w.isMinimized } : w);
      }
    });
  };

  const getTaskbarWindows = () => {
    const nonMcAfeeWindows = windows.filter(w => !w.isMcAfee);
    const mcAfeeWindows = windows.filter(w => w.isMcAfee);
    
    if (mcAfeeWindows.length > 0) {
      return [...nonMcAfeeWindows, { id: 'mcafee', title: 'McAfee Alert', isMcAfee: true }];
    }
    
    return nonMcAfeeWindows;
  };

  return (
    <DesktopWrapper>
      <IconsContainer>
        {icons.map((icon) => (
          <DesktopIcon
            key={icon.name}
            icon={icon.icon}
            name={icon.name}
            onClick={() => handleIconClick(icon.name)}
          />
        ))}
      </IconsContainer>
      {windows.map((window) => (
        !window.isMinimized && (
          <Window
            key={window.id}
            id={window.id}
            title={window.title}
            content={window.content}
            position={window.position}
            onClose={closeWindow}
            onMinimize={() => minimizeWindow(window.id)}
            onDrag={updateWindowPosition}
          />
        )
      ))}
      <Taskbar
        windows={getTaskbarWindows()}
        onWindowClick={handleTaskbarWindowClick}
      />
    </DesktopWrapper>
  );
};

export default Desktop;