// App.js
import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Desktop from './Desktop';
import LoginPage from './LoginPage';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Tahoma', sans-serif;
    overflow: hidden;
  }
`;

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLogin = (user) => {
    console.log(`Logged in as ${user.name}`);
    setLoggedIn(true);
  };

  return (
    <>
      <GlobalStyle />
      {loggedIn ? (
        <Desktop />
      ) : (
        <LoginPage onLogin={handleLogin} />
      )}
    </>
  );
};

export default App;