// Window.jsx
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';

const WindowWrapper = styled.div`
  position: absolute;
  background-color: #ece9d8;
  border: 1px solid #0054e3;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  min-width: 300px;
  min-height: 200px;
  animation: popIn 0.3s ease-out;

  @keyframes popIn {
    0% { transform: scale(0.8); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
  }
`;

const WindowHeader = styled.div`
  background: linear-gradient(to right, #0058e7, #3a93ff);
  color: white;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: move;
`;

const WindowTitle = styled.span`
  flex-grow: 1;
  text-align: left;
`;

const WindowControls = styled.div`
  display: flex;
`;

const WindowButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-left: 5px;
  transition: all 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const MinimizeButton = styled(WindowButton)`
  &:hover {
    background: #ffd700;
    color: black;
  }
`;

const CloseButton = styled(WindowButton)`
  &:hover {
    background: #ff3b3b;
  }
`;

const WindowContent = styled.div`
  padding: 10px;
  max-height: 400px;
  overflow-y: auto;
`;

const Window = ({ id, title, content, position, onClose, onMinimize, onDrag }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setDragOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = useCallback(
    (e) => {
      if (isDragging) {
        onDrag(id, {
          x: e.clientX - dragOffset.x,
          y: e.clientY - dragOffset.y,
        });
      }
    },
    [id, isDragging, dragOffset, onDrag]
  );

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove]);

  return (
    <WindowWrapper style={{ left: position.x, top: position.y }}>
      <WindowHeader onMouseDown={handleMouseDown}>
        <WindowTitle>{title}</WindowTitle>
        <WindowControls>
          <MinimizeButton onClick={() => onMinimize(id)}>_</MinimizeButton>
          <CloseButton onClick={() => onClose(id)}>X</CloseButton>
        </WindowControls>
      </WindowHeader>
      <WindowContent>{content}</WindowContent>
    </WindowWrapper>
  );
};

export default Window;