// Taskbar.jsx
import React from 'react';
import styled from 'styled-components';

const TaskbarWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(to bottom, #245edb, #3a93ff);
  display: flex;
  align-items: center;
  z-index: 1000;
`;

const StartButton = styled.button`
  background: linear-gradient(to bottom, #5aff5a, #00c400);
  border: none;
  color: white;
  font-weight: bold;
  padding: 5px 10px;
  margin-left: 5px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;

  &:hover {
    background: linear-gradient(to bottom, #7fff7f, #00e600);
  }

  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
`;

const TaskbarItem = styled.div`
  background: linear-gradient(to bottom, #6ba6ff, #3a93ff);
  color: white;
  padding: 5px 10px;
  margin-left: 5px;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    background: linear-gradient(to bottom, #7ab6ff, #4aa3ff);
  }
`;

const Taskbar = ({ windows, onWindowClick }) => (
  <TaskbarWrapper>
    <StartButton>
      <img src="/xp-logo.png" alt="Start" />
      Start
    </StartButton>
    {windows.map((window) => (
      <TaskbarItem 
        key={window.id} 
        onClick={() => onWindowClick(window.id)}
      >
        {window.title}
      </TaskbarItem>
    ))}
  </TaskbarWrapper>
);

export default Taskbar;